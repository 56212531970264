<script>
export default {
  name: 'SortMoveHelpCentre',
  data() {
    return {
      videoId: process.env.VUE_APP_VIDEO_ID,
      termsOfBusinessPdf: '/Sort Move - TOB.pdf',
      termsOfBusinessAuctionPackPdf: '/Sort Move Auction Pack - TOB.pdf',
      sortMoveFastStartClientLeaflet: '/fast-start-for-clients.pdf',
    };
  },
  methods: {
    viewCases: function () {
      this.$router.push('/my-cases');
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6">
        <div class="card bg-light">
          <div class="card-header header-elements-inline">
            <h3 class="card-title">{{ $t('helpCentre.processOverviewHeader') }}</h3>
          </div>
          <div class="card-body">
            <p>
              Please familiarise yourself on what our {{ $t('site.processName') }} process is all about by watching our
              short video bellow. Or alternatively download our free {{ $t('site.processName') }} leaflet to help you
              get started.
            </p>
            <a
              class="btn btn-info mb-2"
              :href="this.sortMoveFastStartClientLeaflet"
              download="Sort Move Fast-Start Client Leaflet"
            >
              Download {{ $t('site.companyTitle') }} {{ $t('site.processName') | capitalize }} Client Leaflet
            </a>
            <iframe
              width="100%"
              height="418"
              :src="`https://www.youtube.com/embed/${videoId}`"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <p class="d-block d-sm-block d-md-none">
              <a :href="`https://youtu.be/${videoId}`">Please click here to view the video</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6">
        <div class="card bg-light">
          <div class="card-header header-elements-inline">
            <h3 class="card-title">HooYu Identification video guide</h3>
          </div>
          <div class="card-body">
            <p>
              A short, easy to understand video guide showing the steps you need to follow for your HooYu ID process as
              part of your {{ $t('site.processName') }}.
            </p>
            <iframe
              width="100%"
              height="360"
              src="https://www.youtube.com/embed/BOvs8USkpyA"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <p class="d-block d-sm-block d-md-none">
              <a href="https://youtu.be/BOvs8USkpyA">Please click here to view the video</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-12 col-md-6">
        <div class="card bg-light">
          <div class="card-header header-elements-inline">
            <h3 class="card-title">{{ $t('site.companyTitle') }} - Terms Of Business</h3>
          </div>
          <div class="card-body">
            <p>
              These terms will tell you who we are, what services we will provide to you, how and when we can change the
              clauses within this document, what to do if there is a problem and any other important information.
            </p>
            <a class="btn btn-info mb-2 mr-2" :href="this.termsOfBusinessPdf" download="Sort Move - TOB">
              Download Sale or Purchase Terms of Business
            </a>
            <a
              class="btn btn-info mb-2"
              :href="this.termsOfBusinessAuctionPackPdf"
              download="Sort Move Auction Pack - TOB"
            >
              Download Auction Pack Terms of Business
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
