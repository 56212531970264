<script>
import Loader from '@/components/Loader.vue';
import { mapGetters } from 'vuex';
import loadAllCasesForUser from '@/functions/loadAllCasesForUser';

export default {
  components: { Loader },
  name: 'SortLegalHelpCentre',
  data() {
    return {
      loading: true,
      showDelayInfo: true,
      remortgageGuidePdf: '/OurServices_Sort_Legal__Remortgage_Process.pdf',
    };
  },
  async mounted() {
    // Load the case type related to the current user so that the correct video links are used
    const res = await loadAllCasesForUser(this.cognitoUserId).catch((err) => {
      this.showError = 'case(s)';
      console.error(err);
      return {};
    });
    if (res?.results?.length) {
      this.showDelayInfo = false;
      this.productCode = res.results[0]['product_code'];
    }
    this.loading = false;
  },
  computed: {
    ...mapGetters('auth', ['cognitoUserId']),
    link() {
      switch (this.productCode) {
        case 'To': // Standalone Transfer Of Equity
          return 'https://www.youtube.com/embed/KOLCRl96aHk';
        case 'R':
        case 'FR': // Remortgage
          return 'https://www.youtube.com/embed/KeKSqOmBpbo';
        default:
          // Conveyancing
          return 'https://www.youtube.com/embed/ytJxMGhzQfM';
      }
    },
    title() {
      switch (this.productCode) {
        case 'S':
          return 'Sale';
        case 'P':
          return 'Purchase';
        case 'To':
          return 'Standalone Transfer of Equity';
        case 'R':
        case 'FR':
          return 'Remortgage';
        default:
          return 'Unknown';
      }
    },
  },
};
</script>
<template>
  <loader v-if="loading" class="pb-2"><span v-if="showDelayInfo">Please wait, loading videos</span></loader>
  <section v-else>
    <div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-6">
          <div class="card bg-light">
            <div class="card-header header-elements-inline">
              <h3 class="card-title">HooYu Identification video guide</h3>
            </div>
            <div class="card-body">
              <p>
                A short, easy to understand video guide showing the steps you need to follow for your HooYu ID process
                as part of your {{ title }} process.
              </p>
              <iframe
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                frameborder="0"
                height="360"
                src="https://www.youtube.com/embed/BOvs8USkpyA"
                width="100%"
              ></iframe>
              <p class="d-block d-sm-block d-md-none">
                <a href="https://youtu.be/BOvs8USkpyA">Please click here to view the video</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6">
          <div class="card bg-light">
            <div class="card-header header-elements-inline">
              <h3 class="card-title">
                A step-by-step guide on how to use the {{ $t('site.companyTitle') }} {{ title }} Portal
              </h3>
            </div>
            <div class="card-body">
              <p>
                This short video guide explains the process of completing our {{ title }} Onboarding Portal. It outlines
                the information we need from you prior to starting work on your {{ title }}.
              </p>
              <iframe
                :src="link"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                frameborder="0"
                height="360"
                width="100%"
              ></iframe>
              <p class="d-block d-sm-block d-md-none">
                <a :href="link">Please click here to view the video</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
