<script>
import SortLegalHelpCentre from '../components/SortLegalHelpCentre.vue';
import SortMoveHelpCentre from '../components/SortMoveHelpCentre.vue';

export default {
  components: { SortMoveHelpCentre, SortLegalHelpCentre },
  name: 'HelpCentre',
  data() {
    return {
      tenancy: this.$tenancy,
      cssProps: {
        backgroundImage: `url(${require('@/assets/sortmove-for-sale-2.png')})`,
        backgroundSize: '15rem',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '15rem',
      },
    };
  },
};
</script>

<template>
  <div class="mb-4">
    <div class="card bg-light">
      <div class="card-header header-elements-inline">
        <h1 class="card-title">Helpful Guides</h1>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-8 font-size-lg">
            <p>
              {{ $t('helpCentre.introText') }}
            </p>
          </div>
          <div class="col-12 col-sm-12 col-md-4 font-size-lg" :style="cssProps"></div>
        </div>
      </div>
    </div>

    <sort-move-help-centre v-if="tenancy === 'sortmove'" />
    <sort-legal-help-centre v-if="tenancy === 'sortlegal'" />
  </div>
</template>
